<template>
  <div>
    <v-text-field
      v-model="code"
      :label="label"
      @keyup.enter="inputScan"
      dense
      outlined
      clearable
      hide-details
      autofocus
      ref="inputCodeRef"
    ></v-text-field>

    <v-simple-table class="mb-5" v-if="order && order.id">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.tracking") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.delivery_state") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.delivery_partner_state") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.customer") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.lost_seal") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.is_damage") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.sku") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.product") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center">
            <td>
              {{ order.tracking_id }} <br />
              <v-btn
                x-small
                color="primary"
                v-if="lastPrintedItem && lastPrintedItem.insert_tracking"
                @click="printLastItem"
              >
                <v-icon>mdi-printer</v-icon>{{ $t("labels.nearest_stamp") }}
              </v-btn>
            </td>
            <td>
              {{ $t(`labels.delivery_status_${order.id_delivery_state}`) }}
            </td>
            <td>{{ order.delivery_partner_state }}</td>
            <td>{{ order.customer_company_name }}</td>
            <td>
              <v-switch
                class="switch-center"
                v-model="order.is_lost_seal"
                readonly
                disabled
              ></v-switch>
            </td>
            <td>
              <v-switch
                class="switch-center"
                v-model="order.is_damage"
                readonly
                disabled
              ></v-switch>
            </td>
            <td>{{ order.total_sku }}</td>
            <td>{{ order.total_quantity }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-alert
      v-if="order && order.goods_return_note"
      text
      dense
      color="warning"
      border="left"
      style="font-size: 14px"
    >
      {{ order.goods_return_note }}
    </v-alert>

    <input-number
      v-if="order && order.id"
      class="mb-5"
      type="number"
      v-model.number="money"
      :label="$t('labels.input_if_has_money')"
      dense
      outlined
      clearable
      hide-details
      prepend-inner-icon="mdi-currency-usd"
    />

    <v-simple-table class="mb-5">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.basket_code") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.sku") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.barcode") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.goods") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.goods_description") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.size") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.image") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.export_quantity") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.return_import_quantity") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.sub_quantity") }}
            </th>
            <th role="columnheader" class="text-center"></th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.reason") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.note") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, k) in order.items"
            :key="`checking_${k}_${item.id_goods}`"
            class="text-center"
          >
            <td>
              {{ (item.basket_codes && item.basket_codes.join("; ")) || "" }}
            </td>
            <td>{{ item.sku }}</td>
            <td>{{ item.customer_goods_barcode }}</td>
            <td>{{ item.goods_name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td>
              <ImageViewer v-if="item.url_images" :url="item.url_images" />
            </td>
            <td>{{ item.quantity }}</td>
            <td>
              <div :class="{ 'gr-error-count': item.goods_status === 100 }">
                {{
                  item.goods_status === 100
                    ? "NGỪNG KINH DOANH"
                    : item.import_quantity
                }}
              </div>
            </td>
            <td>
              <div
                :class="{
                  'gr-error-count': item.quantity !== item.import_quantity,
                }"
              >
                {{
                  item.goods_status === 100
                    ? ""
                    : item.quantity - item.import_quantity
                }}
              </div>
            </td>
            <td>
              <div
                class="pa-1"
                v-if="!item.uid_scan_require && item.pack_show_qr_code"
              >
                <qrcode-vue
                  :value="`${item.sku}`"
                  :size="60"
                  level="L"
                ></qrcode-vue>
              </div>
            </td>
            <td>
              <v-autocomplete
                v-model="item.import_reason"
                :items="reasonsOptions"
                class="c-input-xs"
                :label="$t('labels.select_reason')"
                :placeholder="$t('labels.select_reason')"
                dense
                outlined
                hide-details
                single-line
                @change="focusInputCode"
              ></v-autocomplete>
            </td>
            <td>
              <v-textarea
                v-model="item.import_note"
                class="c-input-xs"
                rows="2"
                :label="$t('labels.other_note')"
                :placeholder="$t('labels.other_note')"
                dense
                outlined
                hide-details
                single-line
              ></v-textarea>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('labels.confirm_return_import')"
        :description="
          $t('labels.confirm_return_import_with_tracking', {
            tracking: order.tracking_id,
          })
        "
        :label="$t('labels.tracking_or_delivery_order_id')"
        :placeholder="$t('labels.confirm_return_import_note')"
        :codes="checkingOrderCodes"
        @cancel="cancelConfirm"
        @confirm="confirmReturn"
      />
    </v-dialog>

    <v-dialog v-model="lostLabelDialog" persistent max-width="350px">
      <DialogLostLabel
        v-if="lostLabelDialog"
        :order="order"
        @cancel="hideLostLabelDialog"
      />
    </v-dialog>

    <v-dialog v-model="dialogGoodsExpDate" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.goods_expired_date") }}
        </v-card-title>
        <v-card-text>{{ $t("labels.goods_need_expired_date") }}</v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="year"
                :items="yearOptions"
                class="c-input-xs"
                :label="$t('labels.expired_year')"
                dense
                outlined
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="month"
                :items="monthOptions"
                class="c-input-xs"
                :label="$t('labels.expired_month')"
                dense
                outlined
                clearable
                hide-details
                :disabled="!year"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="day"
                :items="dayOptions"
                :disabled="!month || !year"
                class="c-input-xs"
                :label="$t('labels.expired_day')"
                dense
                outlined
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="lotTracking"
                class="c-input-xs"
                type="text"
                :label="$t('labels.lot')"
                dense
                outlined
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancelGoodsExpDate">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="checkGoodsExpDate"
            :disabled="!year || !month"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTableConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogTableConfirm"
        :title="$t('labels.return_table_code')"
        :description="$t('labels.scan_return_table_description')"
        :label="$t('labels.return_table_code')"
        :placeholder="$t('labels.return_table_code')"
        :codes="checkingCodes"
        :show-cancel-button="false"
        @confirm="setPackingTable"
      />
    </v-dialog>

    <!--<v-dialog v-model="dialogSpecialBarcode" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h6">Hàng hóa đặc biệt</v-card-title>
        <v-card-text>Quét mã Barcode của hàng hóa đặc biệt</v-card-text>

        <v-card-text>
          <v-text-field v-model="special_barcode" class="c-input-xs" @keyup.enter="checkSpecialGoods"
                        :label="$t('labels.barcode')" placeholder="Quét mã Barcode của hàng hóa đặc biệt"
                        autofocus dense single-line outlined clearable hide-details></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancelConfirmSpecial">
            Hủy
          </v-btn>
          <v-btn color="green darken-1" text @click="checkSpecialGoods">
            Xác nhận
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </div>
</template>

<script>
import {
  BARCODE_LOST_LABEL,
  RETURN_IMPORT_REASONS,
  WAREHOUSE_TABLE_PACKING,
} from "@/libs/const";
import { downloadExcelFile, downloadPdfServerFilename, downloadPdfFile } from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
import QrcodeVue from "qrcode.vue";
const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month() + 1;
const CURRENT_DAY = moment().date();

export default {
  name: "Import",
  components: {
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    DialogLostLabel: () => import("@/components/goods_return/DialogLostLabel"),
    QrcodeVue,
  },
  data() {
    return {
      code: null,
      order: {},
      lastItemScanned: {},
      baskets: [],
      isLoading: false,
      money: null,
      reasonsOptions: [...RETURN_IMPORT_REASONS],
      scanType: 1,
      dialogConfirm: false,
      dialogSpecialBarcode: false,
      special_barcode: null,
      scanningQuantity: 0,

      dialogGoodsExpDate: false,
      lostLabelDialog: false,
      goodsExpDate: {},
      day: null,
      month: null,
      year: null,
      lotTracking: null,

      lastPrintedItem: {},

      dialogTableConfirm: false,
      checkingCodes: [],
      tables: [],
    };
  },
  computed: {
    label() {
      let label = "";
      switch (this.scanType) {
        case 1:
          label = this.$t("labels.tracking_or_delivery_order_id");
          break;
        case 2:
          label = this.$t("labels.sku_barcode_or_tracking_delivery_order_id");
          break;
        case 3:
          label = this.$t("labels.sku_barcode_or_basket");
          break;
        default:
          label = this.$t("labels.tracking_or_delivery_order_id");
      }
      return label;
    },

    checkingOrderCodes() {
      const codes = [];
      if (!this.order || !this.order.tracking_id) {
        return codes;
      }
      if (this.order.tracking_id) {
        codes.push(this.order.tracking_id);
      }
      if (this.order.delivery_order_id) {
        codes.push(this.order.delivery_order_id);
      }
      if (this.order.short_delivery_order_id) {
        codes.push(this.order.short_delivery_order_id);
      }
      if (this.order.return_order_id) {
        codes.push(this.order.return_order_id);
        const returnOrderIdNoS = this.order.return_order_id.slice(0, -1);
        codes.push(returnOrderIdNoS);
      }
      if (this.order.return_delivery_order_id) {
        codes.push(this.order.return_delivery_order_id);
      }
      return [...codes];
    },

    checkingItemCodes() {
      const codes = [];
      if (!this.lastItemScanned || !this.lastItemScanned.sku) {
        return codes;
      }
      if (this.lastItemScanned.sku) {
        codes.push("" + this.lastItemScanned.sku);
      }
      if (this.lastItemScanned.customer_goods_barcode) {
        codes.push(this.lastItemScanned.customer_goods_barcode);
      }
      if (this.lastItemScanned.sub_barcode) {
        codes.push(this.lastItemScanned.sub_barcode);
      }
      return codes;
    },

    dayOptions() {
      if (!this.year || !this.month) {
        return [];
      }
      const dayFrom =
        this.year == CURRENT_YEAR && +this.month == CURRENT_MONTH
          ? CURRENT_DAY
          : 1;
      const days = [];
      for (let i = dayFrom; i <= 31; i++) {
        const dayVal = i < 10 ? `0${i}` : `${i}`;
        const checkDay = moment(`${this.year}-${this.month}-${dayVal}`);
        if (checkDay.isValid()) {
          days.push({
            value: dayVal,
            text: dayVal,
          });
        }
      }
      return days;
    },
    monthOptions() {
      const months = [];
      const monthFrom = this.year == CURRENT_YEAR ? CURRENT_MONTH : 1;
      for (let i = monthFrom; i <= 12; i++) {
        const monthVal = i < 10 ? `0${i}` : `${i}`;
        months.push({
          value: monthVal,
          text: monthVal,
        });
      }
      return months;
    },
    yearOptions() {
      let years = [];
      for (let i = CURRENT_YEAR; i < CURRENT_YEAR + 9; i++) {
        years.push({
          value: `${i}`,
          text: `${i}`,
        });
      }
      return years;
    },
  },
  mounted() {
    this.getTable();

    if (
      window.me &&
      window.me.working &&
      window.me.working.return_import &&
      window.me.working.return_import_item
    ) {
      const working = JSON.parse(window.me.working.return_import_item);
      this.order = { ...working.order };
      this.baskets = [...working.baskets];
      this.money = working.money;
      this.scanType = 2;
      this.$vToastify.warning(
        this.$t("messages.warning_tracking_processing", {
          tracking: this.order.tracking_id,
        })
      );
    }
  },
  methods: {
    downloadPdfFile,
    downloadExcelFile,
    downloadPdfServerFilename,
    async inputScan() {
      if (!this.code) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      // const codeSpl = this.code.split('@');
      // const sku = codeSpl[0] || '';

      switch (this.scanType) {
        case 1:
          await this.scanTracking();
          break;
        case 2:
          if (this.checkingOrderCodes.includes(this.code)) {
            const items = this.validateBeforeSubmit();
            if (items && items.length > 0) {
              await this.showPopupConfirm();
            }
          } else {
            await this.scanSku();
          }
          break;
        case 3:
          await this.scanGoodsOrBasket();
          break;
      }
    },

    async scanTracking() {
      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/get-order-import-return",
          {
            tracking: this.code,
          }
        );
        this.isLoading = false;
        this.code = null;

        let total_quantity = 0;
        const items = JSON.parse(data.items || "[]").map((d) => {
          total_quantity += d.quantity;
          d.import_quantity = 0;
          return d;
        });
        data.items = [...items];
        this.order = { ...data, total_sku: items.length, total_quantity };
        this.scanType = 2;

        document.getElementById("success_sound_player").play();
        this.startReturn();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        switch (errMsg) {
          case "OrderNotFound":
            this.$vToastify.error(this.$t("labels.order_code_not_found"));
            document.getElementById(`MaDonKhongTonTai_player`).play();
            break;
          case "OrderNotStatus_0":
            this.$vToastify.error(this.$t("labels.status_invalid_0"));
            document.getElementById(`TrangThaiKhongHopLe_0_player`).play();
            break;
          case "OrderNotStatus_20":
            this.$vToastify.error(this.$t("labels.status_invalid_20"));
            document.getElementById(`TrangThaiKhongHopLe_20_player`).play();
            break;
          case "OrderNotStatus_35":
            this.$vToastify.error(this.$t("labels.status_invalid_35"));
            document.getElementById(`TrangThaiKhongHopLe_35_player`).play();
            break;
          case "OrderNotStatus_40":
            this.$vToastify.error(this.$t("labels.status_invalid_40"));
            document.getElementById(`TrangThaiKhongHopLe_40_player`).play();
            break;
          case "OrderNotStatus_60":
            this.$vToastify.error(this.$t("labels.status_invalid_60"));
            document.getElementById(`TrangThaiKhongHopLe_60_player`).play();
            break;
          case "OrderNotStatus_100":
            this.$vToastify.error(this.$t("labels.status_invalid_100"));
            document.getElementById(`TrangThaiKhongHopLe_100_player`).play();
            break;
          case "OrderNotStatus_160":
            this.$vToastify.error(this.$t("labels.status_invalid_160"));
            document.getElementById(`TrangThaiKhongHopLe_160_player`).play();
            break;
          case "OrderNotStatus_200":
            this.$vToastify.error(this.$t("labels.status_invalid_200"));
            document.getElementById(`TrangThaiKhongHopLe_200_player`).play();
            break;
          case "OrderNotStatus_220":
            this.$vToastify.error(this.$t("labels.status_invalid_220"));
            document.getElementById(`TrangThaiKhongHopLe_220_player`).play();
            break;
          case "OrderNotStatus_240":
            this.$vToastify.error(this.$t("labels.status_invalid_240"));
            document.getElementById(`TrangThaiKhongHopLe_240_player`).play();
            break;
          default:
            this.$vToastify.error(errMsg);
            document.getElementById("error_sound_player").play();
        }
      }
    },

    showLostLabelDialog() {
      this.lostLabelDialog = true;
    },

    hideLostLabelDialog() {
      this.lostLabelDialog = false;
    },

    async scanSku() {
      if (this.code === BARCODE_LOST_LABEL) {
        this.code = null;
        this.isLoading = false;
        this.showLostLabelDialog();
        return false;
      }

      const items = [...(this.order.items || [])];
      const goods_ids = [...items].map((item) => item.id_goods);
      try {
        const { data } = await httpClient.post(
          "/goods/v1/get-by-code-or-uid-and-customer",
          {
            code: this.code,
            id_customer: this.order.id_customer,
            goods_ids,
          }
        );
        if (!data) {
          this.showErrorMsg(this.$t("messages.can_not_find_goods"));
        }
        if (data.expired_date) {
          data.expired_date = moment(data.expired_date).format("YYYY-MM-DD");
        }
        await this.processGoods(data);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    async scanGoodsOrBasket() {
      if (this.code === BARCODE_LOST_LABEL) {
        this.code = null;
        this.isLoading = false;
        this.showLostLabelDialog();
        return false;
      }

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/get-goods-or-basket",
          {
            code: this.code,
            id_customer: this.order.id_customer,
            id_goods: this.lastItemScanned.id_goods,
          }
        );
        if (data.goods) {
          if (data.goods.expired_date) {
            data.goods.expired_date = moment(data.goods.expired_date).format(
              "YYYY-MM-DD"
            );
          }
          await this.processGoods(data.goods);
        } else if (data.basket) {
          await this.processBasket(data.basket);
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.showErrorMsg(errMsg);
      }
    },

    showErrorMsg(msg) {
      this.$vToastify.error(msg);
      this.code = null;
      this.isLoading = false;
      document.getElementById("error_sound_player").play();
    },

    async processGoods(goods) {
      if (this.lastItemScanned && this.lastItemScanned.id_goods) {
        if (this.lastItemScanned.id_goods != goods.id_goods) {
          this.code = null;
          this.isLoading = false;
          this.showErrorMsg(this.$t("messages.scan_goods_or_basket_return"));
          return false;
        }
        if (
          this.lastItemScanned.expired_date &&
          goods.expired_date &&
          this.lastItemScanned.expired_date != goods.expired_date
        ) {
          this.code = null;
          this.isLoading = false;
          this.showErrorMsg(this.$t("messages.wrong_goods_expired_date"));
          return false;
        }
      }

      if (
        goods.available ||
        [0, 2].includes(goods.goods_special_barcode_status)
      ) {
        this.code = null;
        this.isLoading = false;
        this.showErrorMsg(this.$t("messages.uid_wrong_status"));
        return false;
      }

      if (!goods.no_label && !goods.id_goods_special_barcode) {
        this.code = null;
        this.isLoading = false;
        this.showErrorMsg(this.$t("messages.uid_required"));
        return false;
      }

      if (goods.process_exp_date && !goods.expired_date) {
        this.goodsExpDate = { ...goods };
        this.dialogGoodsExpDate = true;
        this.code = null;
        this.isLoading = false;
        return false;
      }

      const items = [...(this.order.items || [])];
      const item = items.find((i) => i.id_goods === goods.id_goods);
      if (!item) {
        this.showErrorMsg(this.$t("messages.goods_no_export_or_receive"));
        return false;
        /*const newItem = {...goods}
        newItem.quantity = 0
        newItem.import_quantity = 1
        newItem.damage = 0
        newItem.is_in_order = false
        if (!newItem.id_goods_special_barcodes) {
          newItem.id_goods_special_barcodes = []
        }
        if (goods.id_goods_special_barcode && goods.goods_uid_printed_on_item) {
          newItem.id_goods_special_barcodes.push(goods.id_goods_special_barcode)
        }
        const lastItemScanned = {...newItem}
        if (goods.expired_date) {
          lastItemScanned.expired_date = moment(goods.expired_date).format('YYYY-MM-DD')
        }
        this.lastItemScanned = {...lastItemScanned}
        items.push(newItem)*/
      } else {
        if (goods.id_goods_special_barcode && item.id_goods_special_barcodes) {
          if (
            item.id_goods_special_barcodes.includes(
              goods.id_goods_special_barcode
            )
          ) {
            this.showErrorMsg(this.$t("messages.special_barcode_scanned"));
            return false;
          }
        }
        const receive_quantity = +item.receive_quantity || +item.quantity;
        if (item.import_quantity >= receive_quantity) {
          this.showErrorMsg(
            this.$t("messages.can_not_scan_overload_export_or_receive")
          );
          return false;
        }

        item.import_quantity += 1;
        item.id_conformity_profile = goods.id_conformity_profile;
        if (!item.id_goods_special_barcodes) {
          item.id_goods_special_barcodes = [];
        }
        if (
          goods.id_goods_special_barcode /* && goods.goods_uid_printed_on_item */
        ) {
          item.id_goods_special_barcodes.push(goods.id_goods_special_barcode);
        }
        const lastItemScanned = { ...item };
        if (goods.expired_date) {
          lastItemScanned.expired_date = moment(goods.expired_date).format(
            "YYYY-MM-DD"
          );
        }
        this.lastItemScanned = { ...lastItemScanned };
      }

      this.scanningQuantity++;
      this.order.items = [...items];
      this.scanType = 3;
      this.code = null;
      this.isLoading = false;
      document.getElementById("success_sound_player").play();
    },

    async processBasket(basket) {
      const checkBasket = this.baskets.find((b) => b.code == this.code);
      if (checkBasket) {
        if (checkBasket.id_goods !== this.lastItemScanned.id_goods) {
          this.showErrorMsg(
            this.$t("messages.basket_using_for_goods", {
              basket: this.code,
              goods: this.lastItemScanned.goods_name,
            })
          );
          return false;
        }
        if (
          checkBasket.expired_date &&
          (!this.lastItemScanned.expired_date ||
            (this.lastItemScanned.expired_date &&
              checkBasket.expired_date !== this.lastItemScanned.expired_date))
        ) {
          this.showErrorMsg(
            this.$t("messages.basket_using_for_goods_expired_date", {
              basket: this.code,
              goods: checkBasket.expired_date,
            })
          );
          return false;
        }
      }

      const order = { ...this.order };
      const items = [...order.items].map((i) => {
        if (!i.basket_codes) {
          i.basket_codes = [];
        }
        if (i.id_goods === this.lastItemScanned.id_goods) {
          if (!i.basket_codes.includes(basket.code)) {
            i.basket_codes.push(basket.code);
          }
        }
        return i;
      });
      order.items = [...items];
      this.order = { ...order };
      if (this.lastItemScanned.expired_date) {
        basket.expired_date = this.lastItemScanned.expired_date;
      }
      if (!checkBasket) {
        this.baskets.push({ ...basket, quantity: this.scanningQuantity });
      } else {
        checkBasket.quantity += this.scanningQuantity;
      }

      this.lastItemScanned = {};
      this.scanningQuantity = 0;
      this.scanType = 2;
      this.code = null;
      this.isLoading = false;
      this.updateWorking({
        type: "return_import",
        item: JSON.stringify({
          order: this.order,
          baskets: this.baskets,
          money: this.money,
        }),
      });
      document.getElementById("success_sound_player").play();
      // await this.printStamp()
    },

    async printLastItem() {
      await this.downloadPdfServerFilename("/common/v1/generate-goods-stamp", {
        id_goods: this.lastPrintedItem.id_goods,
        insert_tracking: this.lastPrintedItem.insert_tracking,
        client_time: moment().format("HH:mm DD/MM/YYYY"),
      });
    },

    async printStamp() {
      if (this.lastItemScanned.goods_uid_printed_on_item) {
        this.lastItemScanned = {};
        this.scanningQuantity = 0;
        this.scanType = 2;
        this.code = null;
        this.isLoading = false;
        return false;
      }

      console.log(this.lastItemScanned);
      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/generate-goods-uid",
          {
            quantity: this.scanningQuantity,
            id_goods: this.lastItemScanned.id_goods,
            id_goods_issue_detail: this.order.id_goods_issue_detail,
            lot_tracking: this.lastItemScanned.lot_tracking,
            expired_date: this.lastItemScanned.expired_date,
            related_ids: this.lastItemScanned.id_goods_special_barcodes,
          }
        );
        const { insertTracking, gsbIds } = data;
        this.isLoading = false;

        await this.downloadPdfServerFilename(
          "/common/v1/generate-goods-stamp",
          {
            id_goods: this.lastItemScanned.id_goods,
            insert_tracking: insertTracking,
            client_time: moment().format("HH:mm DD/MM/YYYY"),
          }
        );
        const lastPrintedItem = {
          id_goods: this.lastItemScanned.id_goods,
          sku: this.lastItemScanned.sku,
          id_conformity_profile: this.lastItemScanned.id_conformity_profile,
          tracking_id: this.order.tracking_id,
          insert_tracking: insertTracking,
        };
        this.lastPrintedItem = { ...lastPrintedItem };

        const items = [...(this.order.items || [])];
        const item = items.find(
          (i) => i.id_goods === this.lastItemScanned.id_goods
        );
        if (item) {
          if (!item.id_goods_special_barcodes) {
            item.id_goods_special_barcodes = [];
          }
          item.id_goods_special_barcodes.push(...gsbIds);
        }

        this.lastItemScanned = {};
        this.scanningQuantity = 0;
        this.scanType = 2;
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        this.updateWorking({
          type: "return_import",
          item: JSON.stringify({
            order: this.order,
            baskets: this.baskets,
            money: this.money,
          }),
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.showErrorMsg(errMsg);
      }
    },

    showPopupConfirm() {
      this.code = null;
      this.dialogConfirm = true;
    },

    cancelConfirm() {
      this.dialogConfirm = false;
      this.isLoading = false;
    },

    resetData() {
      this.scanType = 1;
      this.order = {};
      this.lastItemScanned = {};
      this.baskets = [];
      this.money = null;
      this.isLoading = false;
    },

    async confirmReturn() {
      const items = this.validateBeforeSubmit();

      /*if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true*/

      try {
        await httpClient.post("/goods-return/v1/confirm-import", {
          tracking: this.order.tracking_id,
          items,
          baskets: this.baskets,
          money: this.money,
        });
        this.cancelConfirm();
        this.resetData();
        this.updateWorking({ type: "return_import", item: null });
        document.getElementById("success_sound_player").play();
        this.focusInputCode();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },

    validateBeforeSubmit() {
      // bo qua hang hoa ngung kinh doanh & validate
      let checkItemNotes = [];
      let checkItemBaskets = [];
      let checkItemSpecials = [];
      const items = [...this.order.items]
        .filter((i) => i.goods_status !== 100)
        .map((j) => {
          j.note = `${j.import_reason || ""} ${j.import_note || ""}`;
          if (
            j.import_quantity != j.quantity &&
            (!j.note || j.note.length < 15)
          ) {
            checkItemNotes.push(j.sku);
          }
          if (
            j.import_quantity > 0 &&
            (!j.basket_codes || j.basket_codes.length === 0)
          ) {
            checkItemBaskets.push(j.sku);
          }
          if (
            j.import_quantity > 0 &&
            j.goods_uid_printed_on_item &&
            (!j.id_goods_special_barcodes ||
              j.id_goods_special_barcodes.length === 0)
          ) {
            checkItemSpecials.push(j.sku);
          }
          return j;
        });

      if (checkItemNotes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.goods_return_wrong_quantity", {
            goods: checkItemNotes.join(", "),
          })
        );
      }
      if (checkItemBaskets.length > 0) {
        this.$vToastify.error(
          this.$t("messages.goods_return_wrong_basket", {
            goods: checkItemBaskets.join(", "),
          })
        );
      }
      if (checkItemSpecials.length > 0) {
        this.$vToastify.error(
          this.$t("messages.goods_return_wrong_special_barcode", {
            goods: checkItemSpecials.join(", "),
          })
        );
      }
      if (
        checkItemNotes.length > 0 ||
        checkItemBaskets.length > 0 ||
        checkItemSpecials.length > 0
      ) {
        document.getElementById("error_sound_player").play();
        this.isLoading = false;
        this.code = null;
        return false;
      }
      return items;
    },

    focusInputCode() {
      this.$refs.inputCodeRef.focus();
    },

    expiredDate() {
      let expired_date = null;
      if (this.year) {
        if (!this.month) {
          this.$vToastify.warning(this.$t("messages.unselected_month"));
          return false;
        }

        if (this.day) {
          const checkDay = moment(`${this.year}-${this.month}-${this.day}`);
          if (!checkDay.isValid()) {
            this.$vToastify.warning(this.$t("messages.wrong_expired_date"));
            return false;
          }
          expired_date = checkDay.format("YYYY-MM-DD");
        }

        const checkDay = moment(`${this.year}-${this.month}`);
        if (!checkDay.isValid()) {
          this.$vToastify.warning(this.$t("messages.wrong_expired_date"));
          return false;
        }
        expired_date = checkDay.endOf("month").format("YYYY-MM-DD");
      }
      return expired_date;
    },

    checkGoodsExpDate() {
      const expired_date = this.expiredDate();
      if (!expired_date) {
        this.$vToastify.warning(this.$t("messages.un_selected_expired_date"));
        return false;
      }
      const goods = {
        ...this.goodsExpDate,
        expired_date,
        lot_tracking: this.lotTracking,
      };
      this.processGoods(goods);
      this.cancelGoodsExpDate();
    },

    cancelGoodsExpDate() {
      this.year = null;
      this.month = null;
      this.day = null;
      this.lotTracking = null;
      this.dialogGoodsExpDate = false;
    },

    getTable() {
      const table = localStorage.getItem("_pktb");
      if (!table) {
        this.showTableConfirmDialog();
        return false;
      }
      const { date, code, id } = JSON.parse(table);
      const today = moment().format("YYYYMMDD");
      if (!date || !code || !id || date !== today) {
        this.showTableConfirmDialog();
        return false;
      }

      const basket_code = localStorage.getItem("_pbct1");
      if (!basket_code) {
        return false;
      }
      this.$vToastify.warning(
        this.$t("messages.processing_one_basket", { basket_code: basket_code })
      );
    },
    showTableConfirmDialog() {
      httpClient
        .post("/me/v1/get-warehouse-tables", { type: WAREHOUSE_TABLE_PACKING })
        .then(({ data }) => {
          const checkingCodes = [...data].map((d) => d.code);
          this.checkingCodes = [...checkingCodes];
          this.tables = [...data];
          this.dialogTableConfirm = true;
        });
    },
    hideTableConfirmDialog() {
      this.dialogTableConfirm = false;
    },
    async setPackingTable(code) {
      const { employee } = window.me;
      if (!code) {
        this.$vToastify.error(this.$t("messages.packing_table_code_required"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      const table = this.tables.find((t) => t.code === code);
      if (!table) {
        this.$vToastify.error(this.$t("messages.packing_table_code_wrong"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      if (table.id_employee && table.id_employee !== employee.id) {
        this.$vToastify.error(
          this.$t("messages.packing_table_code_using_other_employee", {
            name: table.employee_name,
          })
        );
        document.getElementById("error_sound_player").play();
        return false;
      }

      try {
        await httpClient.post("/me/v1/set-using-warehouse-table", {
          id: table.id,
          type: WAREHOUSE_TABLE_PACKING,
        });
        const today = moment().format("YYYYMMDD");
        const jsonStore = JSON.stringify({
          date: today,
          code: table.code,
          id: table.id,
        });
        localStorage.setItem("_pktb", jsonStore);
        document.getElementById("success_sound_player").play();
        this.hideTableConfirmDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    startReturn() {
      if (this.order && this.order.id) {
        const table = JSON.parse(localStorage.getItem("_pktb") || "{id: null}");
        const idTable = table.id || null;
        httpClient.post("/goods-return/v1/start-import", {
          id: this.order.id,
          id_table: idTable,
        });
      }
    },
  },
};
</script>

<style scoped></style>
